/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
	Flex,
	Image,
	Icon,
	// Link,
	Text,
	useColorModeValue,
	SimpleGrid,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
// Assets
// import Typescript from "assets/img/free/started/Typescript.png";
// import Javascript from "assets/img/free/started/Javascript.png";
// import NextJS from "assets/img/free/started/NextJS.png";
import Typescript from "assets/img/free/started/typescript-logo-image.png";
import Javascript from "assets/img/free/started/javascript-logo-image.png";
import ReactLogo from "assets/img/free/started/react-logo-image.png";
import Tailwind from "assets/img/free/started/tailwindcss-logo-image.png";
import Django from "assets/img/free/started/django-logo-image.png";
import Flask from "assets/img/free/started/flask-logo-image.png";
import Nodejs from "assets/img/free/started/nodejs-logo-image.png";
// import Html from "assets/img/free/started/html-logo-image.png";
// import TailwindJS from "assets/img/free/started/tailwindjs.png";
import NextJS from "assets/img/free/started/nextjs-logo-image.png";
import Chakra from "assets/img/free/started/chakra-logo-image.png";

// Custom components
import InnerContent from "layouts/innerContent";
import TechCard from "./components/TechCard";

export default function Tools() {
	// Chakra Color Mode
	const textColor = useColorModeValue("#120F43", "white");
	// const brandColor = useColorModeValue('brand.500', 'white');
	return (
		<Flex
			w='100%'
			maxW='100%'
			direction={{ base: "column" }}
			pt={{ base: "100px", md: "140px", lg: "120px" }}
			pb={{ base: "70px", md: "100px", lg: "120px" }}
			overflow='hidden'
			bgSize='cover'
			position='relative'
			id='version'>
			<InnerContent justifyContent='flex-start'>
				<Flex
					maxW='100%'
					direction='column'
					justify='center'
					alignItems='center'
					width='stretch'
					px={{ base: "20px", md: "20px", xl: "0px" }}>
					<Flex
						direction='column'
						mx='auto'
						justify='center'
						alignItems='center'
						mb='40px'
						maxW={{ base: "100%", md: "100%", lg: "100%", xl: "80%" }}
						textAlign='center'>
						<Text
							as='h3'
							fontWeight='700'
							letterSpacing='2px'
							bg='brand.500'
							bgClip='text'
							fontSize={{ base: "xs", md: "md" }}
							w='100%'
							mb='10px'>
							CHOOSE YOUR TRENDY REACT TEMPLATE
						</Text>
						<Text
							as='h2'
							color={textColor}
							fontWeight='800'
							fontSize={{ base: "24px", md: "34px", lg: "46px" }}
							lineHeight={{
								base: "30px",
								md: "44px",
								lg: "52px",
							}}
							mb={{ base: "14px", lg: "30px" }}>
							Get started with React and your favorite programming
							language/design library
						</Text>
						<Text
							color='gray.600'
							fontSize={{ base: "md", md: "md", xl: "lg" }}
							lineHeight='30px'
							fontWeight='500'
							letterSpacing='0px'
							w={{ base: "100%", md: "86%", lg: "80%" }}
							mb='40px'>
							Choose Horizon UI, the most beautiful React web app template, and
							integrate it with your favorite and popular programming languages
							/ frameworks!
						</Text>
					</Flex>
				</Flex>
				<SimpleGrid
					w='100%'
					columns={{ base: "1", md: "2", lg: "3" }}
					gap='20px'
					px={{ base: "20px", xl: "0px" }}>
					<TechCard
						encharge
						// link='https://github.com/horizon-ui/horizon-ui-chakra-ts/archive/refs/heads/feature/react-table-v8.zip'
						enchargeCode='306a99d2-6bb3-49d7-abff-472975217978'
						buttonText={`Download for Free`}
						title='Chakra UI + React + TS'
						description='Create your free React admin with Chakra UI and Typescript'
						techs={
							<Flex mb='25px' align={"center"} gap='14px'>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#D8FDFF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Chakra}
										alt='chakra ui logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#ECE8FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={ReactLogo}
										alt='react js logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#EBF1FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Typescript}
										alt='typescript logo'
									/>
								</Flex>
							</Flex>
						}
						productCode='801683'
					/>
					<TechCard
						encharge
						// link='https://github.com/horizon-ui/horizon-ui-chakra/archive/refs/heads/main.zip'
						enchargeCode='4a32b4c0-0bda-426e-adb1-bed8666ddc31'
						buttonText={`Download for Free`}
						title='Chakra UI + React + JS'
						description='Create your free React admin with Chakra UI and Javascript'
						techs={
							<Flex mb='25px' align={"center"} gap='14px'>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#D8FDFF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Chakra}
										alt='chakra ui logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#ECE8FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={ReactLogo}
										alt='react js logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#FFF7E1'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Javascript}
										alt='javascript logo'
									/>
								</Flex>
							</Flex>
						}
						productCode='801685'
					/>
					<TechCard
						encharge
						// link='https://github.com/horizon-ui/horizon-ui-chakra-nextjs/archive/refs/heads/main.zip'
						enchargeCode='aca2aec0-bb2a-4a85-88f6-6013ea43e082'
						buttonText={`Download for Free`}
						title='Chakra UI + React + NextJS'
						description='Create your free React admin with Chakra UI and NextJS'
						techs={
							<Flex mb='25px' align={"center"} gap='14px'>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#D8FDFF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Chakra}
										alt='chakra ui logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#ECE8FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={ReactLogo}
										alt='react js logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#E7E6FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={NextJS}
										alt='nextjs logo'
									/>
								</Flex>
							</Flex>
						}
						productCode='801686'
					/>
					{/* <TechCard
            encharge
            enchargeCode="aca2aec0-bb2a-4a85-88f6-6013ea43e082"
            buttonText={`Coming soon...`}
            title="Tailwind CSS & HTML5"
            description="Start using Horizon UI with TailwindCSS & HTML for free"
            techs={
              <Flex mb="25px" align={"center"} gap="14px">
                <Flex
                  p={{ base: "12px", lg: "8px" }}
                  borderRadius={{ base: "14px", lg: "16px" }}
                  bg="#DDF7FF"
                  filter="saturate(0)"
                >
                  <Image maxW={{ base: "34px", md: "50px",lg:"40px", xl:"50px" }} src={Tailwind}
                  alt="tailwindcss logo" />
                </Flex>
                <Flex
                  justify={"center"}
                  align="center"
                  minW="30px"
                  h="30px"
                  p="4px"
                  filter="saturate(0)"
                  borderRadius="100%"
                  bg="#EBF1FF"
                >
                  <Icon as={MdAdd} w="20px" h="20px" color="white" />
                </Flex>
                <Flex
                  p={{ base: "12px", lg: "8px" }}
                  borderRadius={{ base: "14px", lg: "16px" }}
                  bg="#FFF5E6"
                  filter="saturate(0)"
                >
                  <Image maxW={{ base: "34px", md: "50px",lg:"40px", xl:"50px" }} src={Html} />
                </Flex>
              </Flex>
            }
            productCode="801683"
            disabled
          /> */}
					<TechCard
						encharge
						// link='https://github.com/horizon-ui/horizon-tailwind-react-ts/archive/refs/heads/main.zip'
						enchargeCode='e9f40945-a98a-4e2e-8eef-0ddfe9ffde97'
						buttonText={`Download for Free`}
						title='Tailwind CSS + React + TS'
						description='Create your free React admin with Tailwind CSS and Typescript'
						techs={
							<Flex mb='25px' align={"center"} gap='14px'>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#DDF7FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Tailwind}
										alt='tailwind css logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#ECE8FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={ReactLogo}
										alt='react js logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#EBF1FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Typescript}
										alt='typescript logo'
									/>
								</Flex>
							</Flex>
						}
						productCode='801683'
					/>
					<TechCard
						encharge
						// link='https://github.com/horizon-ui/horizon-tailwind-react/archive/refs/heads/main.zip'
						enchargeCode='e309bf9e-4cdf-4bfb-a473-6d0a1485a5da'
						buttonText={`Download for Free`}
						title='Tailwind CSS + React + JS'
						description='Create your free React admin with Tailwind CSS and Javascript'
						techs={
							<Flex mb='25px' align={"center"} gap='14px'>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#DDF7FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Tailwind}
										alt='tailwindcss logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#ECE8FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={ReactLogo}
										alt='react js logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#FFF7E1'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Javascript}
										alt='javascript logo'
									/>
								</Flex>
							</Flex>
						}
						productCode='801683'
					/>
					<TechCard
						encharge
						// link='https://github.com/horizon-ui/horizon-tailwind-react-nextjs/archive/refs/heads/main.zip'
						enchargeCode='d3095bcc-c3d1-48cc-af11-7166c5c6c9d6'
						buttonText={`Download for Free`}
						title='Tailwind CSS + React + NextJS'
						description='Create your free React admin with Tailwind CSS and NextJS'
						techs={
							<Flex mb='25px' align={"center"} gap='14px'>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#DDF7FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={Tailwind}
										alt='tailwindcss logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#ECE8FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={ReactLogo}
										alt='react js logo'
									/>
								</Flex>
								<Flex
									justify={"center"}
									align='center'
									minW='30px'
									h='30px'
									p='4px'
									borderRadius='100%'
									bg='#EBF1FF'>
									<Icon as={MdAdd} w='20px' h='20px' color='white' />
								</Flex>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#E7E6FF'>
									<Image
										maxW={{ base: "34px", md: "40px", xl: "50px" }}
										src={NextJS}
										alt='nextjs logo'
									/>
								</Flex>
							</Flex>
						}
						productCode='801686'
					/>
					<TechCard
						buttonText={`Download for Free`}
						buttonLink='https://appseed.us/product/horizon-ui/api-server-nodejs/?ref=horizon-ui.com'
						title='NodeJS API'
						description='Create your free React full stack app with Horizon and Nodejs API'
						techs={
							<Flex mb='25px' align={"center"}>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#E5FFC5'>
									<Image
										w={{ base: "103px", lg: "50px" }}
										h={{ base: "103px", lg: "50px" }}
										src={Nodejs}
									/>
								</Flex>
							</Flex>
						}
						productCode='801683'
					/>
					<TechCard
						buttonText={`Download for Free`}
						buttonLink='https://appseed.us/product/horizon-ui/api-server-nodejs/?ref=horizon-ui.com'
						title='Django API'
						description='Create your free React full stack app with Horizon and Django API'
						techs={
							<Flex mb='25px' align={"center"}>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#E0F4DB'>
									<Image
										w={{ base: "103px", lg: "50px" }}
										h={{ base: "103px", lg: "50px" }}
										src={Django}
									/>
								</Flex>
							</Flex>
						}
						enchargeTechs={
							<Flex mb='25px' align={"center"}>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#E0F4DB'>
									<Image
										w={{ base: "103px", lg: "50px" }}
										h={{ base: "103px", lg: "50px" }}
										src={Django}
									/>
								</Flex>
							</Flex>
						}
						productCode='801683'
					/>
					<TechCard
						buttonText={`Download for Free`}
						buttonLink='https://appseed.us/product/horizon-ui/api-server-nodejs/?ref=horizon-ui.com'
						title='Flask API'
						description='Create your free React full stack app with Horizon and Flask API'
						techs={
							<Flex mb='25px' align={"center"}>
								<Flex
									p={{ base: "12px", lg: "8px" }}
									borderRadius={{ base: "14px", lg: "16px" }}
									bg='#E7EBF1'>
									<Image
										w={{ base: "103px", lg: "50px" }}
										h={{ base: "103px", lg: "50px" }}
										src={Flask}
									/>
								</Flex>
							</Flex>
						}
						productCode='801683'
					/>
				</SimpleGrid>
			</InnerContent>
		</Flex>
	);
}
