/*eslint-disable*/
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
	Box,
	Button,
	Flex,
	Image,
	Icon,
	Avatar,
	Text,
	Link,
	useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card";
import avatar from "assets/img/free/numbers/Image-4.png";
import GithubLogo from "assets/img/free/numbers/github-numbers-logo.png";
import FigmaLogo from "assets/img/free/numbers/figma-numbers-logo.png";
import DiscordLogo from "assets/img/free/numbers/discord-numbers-logo.png";
import ProductHuntLogo from "assets/img/free/numbers/producthunt-numbers-logo.png";
import InnerContent from "layouts/innerContent";
import { IoIosStar } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";
import { NumbersBg } from "components/icons/Icons";

export default function GetStarted() {
	// Chakra Color Mode
	const textColor = useColorModeValue("#120F43", "white");
	const borderColor = useColorModeValue("gray.300", "white");
	const textColorSecondary = useColorModeValue("gray.600", "white");
	const brandColor = useColorModeValue("brand.500", "white");
	return (
		<Flex
			w='100%'
			direction={{ base: "column" }}
			overflow='hidden'
			bgSize='cover'
			position='relative'
			pb={{ base: "100px", md: "140px", xl: "140px" }}>
			<InnerContent px={{ base: "20px", md: "20px", xl: "0px" }}>
				<Flex
					direction={{ base: "column", lg: "row" }}
					width='100%'
					justify='space-between'>
					<Flex
						me={{ lg: "80px" }}
						mb={{ base: "40px", lg: "0px" }}
						direction='column'
						width='stretch'>
						<Flex
							direction='column'
							my='auto'
							zIndex='1'
							alignItems={{ base: "center", lg: "unset" }}>
							<Text
								as='h3'
								fontWeight='700'
								letterSpacing='2px'
								bg='brand.500'
								bgClip='text'
								fontSize={{ base: "xs", md: "md" }}
								textAlign={{ base: "center", lg: "left" }}
								w='100%'
								mb='10px'>
								HORIZON UI IN NUMBERS
							</Text>
							<Text
								as='h2'
								fontWeight='800'
								color={textColor}
								fontSize={{ base: "28px", md: "40px", xl: "48px" }}
								lineHeight={{ base: "34px", md: "50px", lg: "52px" }}
								textAlign={{ base: "center", lg: "left" }}
								mb='20px'
								w={{ md: "70%", lg: "100%" }}
								maxW={{ base: "100%", md: "unset" }}>
								Expand your horizons with Horizon UI!
							</Text>
							<Text
								color={textColorSecondary}
								fontSize={{ base: "md", md: "md", xl: "lg" }}
								w={{ base: "100%", md: "70%", lg: "100%" }}
								fontWeight='500'
								textAlign={{ base: "center", lg: "left" }}
								mb='30px'
								letterSpacing='0px'>
								"A useful react admin template that perfectly combines the UI
								with UX. The diversity of screens (NFT Page, Car Interface,
								Smart Home, etc.) helps the user reduce many workdays. For sure,
								you can broaden your web design horizons with Horizon!"
							</Text>
							<Flex
								alignItems='center'
								justifyContent={{ base: "center", lg: "unset" }}>
								<Avatar h='48px' w='48px' me='20px' src={avatar} />
								<Box>
									<Text
										color={textColor}
										fontSize='md'
										letterSpacing='0px'
										fontWeight='700'>
										Elisa Paduraru
									</Text>
									<Flex mb='4px' align='center'>
										<Text
											color={textColorSecondary}
											fontSize='md'
											fontWeight='500'
											me='5px'>
											Chief Design Officer at
										</Text>
										<Link
											isExternal='true'
											href='https://creative-tim.com/?ref=horizon-ui.com'
											color={brandColor}
											fontWeight='700'
											fontSize='md'>
											Creative Tim
										</Link>
									</Flex>
									<Flex
										justify={{ base: "start", md: "start" }}
										alignItems='center'>
										<Icon as={IoIosStar} w='18px' h='18px' color='#F6AD55' />
										<Icon as={IoIosStar} w='18px' h='18px' color='#F6AD55' />
										<Icon as={IoIosStar} w='18px' h='18px' color='#F6AD55' />
										<Icon as={IoIosStar} w='18px' h='18px' color='#F6AD55' />
										<Icon
											as={IoIosStar}
											w='18px'
											h='18px'
											color='#F6AD55'
											me='8px'
										/>
									</Flex>
								</Box>
							</Flex>
						</Flex>
					</Flex>
					<Flex
						position={"relative"}
						ms='auto'
						direction='column'
						width='stretch'>
						<Icon
							as={NumbersBg}
							w='750px'
							zIndex='0'
							h='600px'
							position={"absolute"}
							top='60px'
							right='-120px'
						/>
						<Box maxW='100%' my='auto' zIndex={"3"}>
							<Card
								maxW={{ base: "100%", md: "unset" }}
								minW={{ base: "200px", lg: "500px", xl: "545px" }}
								p='30px'
								mb='20px'>
								<Flex
									align='center'
									direction={{ base: "column", lg: "row" }}
									justify={{ base: "center", lg: "unset" }}
									flexWrap={{ base: "wrap", lg: "unset" }}>
									<Image
										alt='github logo'
										borderRadius='full'
										w={{ base: "50px", md: "74px" }}
										me={{ base: "0px", lg: "20px" }}
										mb={{ base: "10px", md: "20px", lg: "0px" }}
										minW={{ base: "50px", md: "74px" }}
										src={GithubLogo}
									/>
									<Flex
										me={{ base: "0px", lg: "auto" }}
										mb={{ base: "20px", md: "unset" }}
										direction='column'
										justify={{ base: "center", lg: "unset" }}
										align={{ base: "center", lg: "unset" }}
										flexWrap={{ base: "wrap", lg: "unset" }}>
										<Text
											fontWeight='800'
											mb='10px'
											color={textColor}
											fontSize={{ base: "22px", md: "34px" }}
											lineHeight={{ base: "100%", md: "100%" }}
											maxW={{ base: "80%", md: "unset" }}
											textAlign={{ base: "center", lg: "start" }}>
											4,150+
										</Text>
										<Text
											fontWeight='700'
											color='gray.400'
											mb={{ base: "0", md: "20px", lg: "0" }}
											fontSize={{ base: "sm", md: "sm" }}
											lineHeight={{ base: "100%", md: "100%" }}
											letterSpacing='2.48px'>
											TOTAL STARS ON GITHUB
										</Text>
									</Flex>
									{/* <GitHubButton
                    w="200px"
                    size="large"
                    href="https://github.com/horizon-ui/horizon-tailwind-react"
                    data-icon="octicon-star"
                    data-show-count="true"
                    aria-label="Star horizon-ui/horizon-tailwind-react on GitHub"
                  >
                    Star
                  </GitHubButton> */}
									<Link isExternal='true' href='https://github.com/horizon-ui'>
										<Button
											variant='transparent'
											border='1px solid'
											borderColor={borderColor}
											color={textColor}
											fontSize='sm'
											borderRadius='45px'
											my='auto'
											px='24px'
											w={{ base: "270px", md: "160px" }}
											h='54px'>
											Github repos
											<Icon
												as={MdChevronRight}
												ms='5px'
												mt='2px'
												h='16px'
												w='16px'
											/>
										</Button>
									</Link>
								</Flex>
							</Card>
							<Card
								maxW={{ base: "100%", md: "unset" }}
								minW={{ base: "200px", lg: "500px", xl: "545px" }}
								p='30px'
								mb='20px'
								me={{ base: "0px", xl: "30px" }}>
								<Flex
									align='center'
									direction={{ base: "column", lg: "row" }}
									justify={{ base: "center", lg: "unset" }}
									flexWrap={{ base: "wrap", md: "unset" }}>
									<Image
										alt='figma logo'
										borderRadius='full'
										w={{ base: "50px", md: "74px" }}
										me={{ base: "0px", lg: "20px" }}
										mb={{ base: "10px", md: "20px", lg: "0px" }}
										minW={{ base: "50px", md: "74px" }}
										src={FigmaLogo}
									/>
									<Flex
										me={{ base: "0px", lg: "auto" }}
										mb={{ base: "20px", md: "unset" }}
										direction='column'
										justify={{ base: "center", lg: "unset" }}
										align={{ base: "center", lg: "unset" }}
										flexWrap={{ base: "wrap", md: "unset" }}>
										<Text
											fontWeight='800'
											mb='10px'
											color={textColor}
											fontSize={{ base: "22px", md: "34px" }}
											lineHeight={{ base: "100%", md: "100%" }}
											maxW={{ base: "80%", md: "unset" }}>
											44,000+
										</Text>
										<Text
											fontWeight='700'
											color='gray.400'
											mb={{ base: "0", md: "20px", lg: "0" }}
											fontSize={{ base: "sm", md: "sm" }}
											lineHeight={{ base: "100%", md: "100%" }}
											letterSpacing='2.48px'>
											FIGMA DUPLICATES
										</Text>
									</Flex>
									<Link
										isExternal='true'
										href='https://www.figma.com/community/file/1098131983383434513/Horizon-UI---Trendiest-Open-Source-Admin-Template'>
										<Button
											variant='transparent'
											border='1px solid'
											borderColor={borderColor}
											color={textColor}
											fontSize='sm'
											borderRadius='45px'
											my='auto'
											px='24px'
											w={{ base: "270px", md: "190px" }}
											h='54px'>
											Horizon for Figma
											<Icon
												as={MdChevronRight}
												ms='5px'
												mt='2px'
												h='16px'
												w='16px'
											/>
										</Button>
									</Link>
								</Flex>
							</Card>
							<Card
								maxW={{ base: "100%", md: "unset" }}
								minW={{ base: "200px", lg: "500px", xl: "545px" }}
								p='30px'
								mb='20px'
								me={{ base: "0px", xl: "30px" }}>
								<Flex
									align='center'
									direction={{ base: "column", lg: "row" }}
									justify={{ base: "center", lg: "unset" }}
									flexWrap={{ base: "wrap", md: "unset" }}>
									<Image
										alt='discord logo'
										borderRadius='full'
										w={{ base: "50px", md: "74px" }}
										me={{ base: "0px", lg: "20px" }}
										mb={{ base: "10px", md: "20px", lg: "0px" }}
										minW={{ base: "50px", md: "74px" }}
										src={DiscordLogo}
									/>
									<Flex
										me={{ base: "0px", lg: "auto" }}
										mb={{ base: "20px", md: "unset" }}
										direction='column'
										justify={{ base: "center", lg: "unset" }}
										align={{ base: "center", lg: "unset" }}
										flexWrap={{ base: "wrap", md: "unset" }}>
										<Text
											fontWeight='800'
											mb='10px'
											color={textColor}
											fontSize={{ base: "22px", md: "34px" }}
											lineHeight={{ base: "100%", md: "100%" }}
											maxW={{ base: "80%", md: "unset" }}>
											970+
										</Text>
										<Text
											fontWeight='700'
											color='gray.400'
											mb={{ base: "0", md: "20px", lg: "0" }}
											fontSize={{ base: "sm", md: "sm" }}
											lineHeight={{ base: "100%", md: "100%" }}
											letterSpacing='2.48px'>
											DISCORD MEMBERS
										</Text>
									</Flex>
									<Link isExternal='true' href='https://discord.gg/f6tEKFBd4m'>
										<Button
											variant='transparent'
											border='1px solid'
											borderColor={borderColor}
											color={textColor}
											fontSize='sm'
											borderRadius='45px'
											my='auto'
											px='24px'
											w={{ base: "270px", md: "200px" }}
											h='54px'>
											Join our community
											<Icon
												as={MdChevronRight}
												ms='5px'
												mt='2px'
												h='16px'
												w='16px'
											/>
										</Button>
									</Link>
								</Flex>
							</Card>
							<Card
								maxW={{ base: "100%", md: "unset" }}
								minW={{ base: "200px", lg: "500px", xl: "545px" }}
								p='30px'>
								<Flex
									align='center'
									direction={{ base: "column", lg: "row" }}
									justify={{ base: "center", lg: "unset" }}
									flexWrap={{ base: "wrap", md: "unset" }}>
									<Image
										alt='producthunt logo'
										borderRadius='full'
										w={{ base: "50px", md: "74px" }}
										me={{ base: "0px", lg: "20px" }}
										mb={{ base: "10px", md: "20px", lg: "0px" }}
										minW={{ base: "50px", md: "74px" }}
										src={ProductHuntLogo}
									/>
									<Flex
										me={{ base: "0px", lg: "auto" }}
										mb={{ base: "20px", md: "unset" }}
										direction='column'
										justify={{ base: "center", lg: "unset" }}
										align={{ base: "center", lg: "unset" }}
										flexWrap={{ base: "wrap", md: "unset" }}>
										<Text
											fontWeight='800'
											mb='10px'
											color={textColor}
											fontSize={{ base: "22px", md: "34px" }}
											lineHeight={{ base: "100%", md: "100%" }}
											maxW={{ base: "80%", md: "unset" }}>
											#3 🏆
										</Text>
										<Text
											fontWeight='700'
											color='gray.400'
											mb={{ base: "0", md: "20px", lg: "0" }}
											fontSize={{ base: "sm", md: "sm" }}
											lineHeight={{ base: "100%", md: "100%" }}
											letterSpacing='2.48px'
											whiteSpace='nowrap'>
											ON PRODUCT HUNT
										</Text>
									</Flex>
									<Box
										transform={{
											base: "scale(0.8) translate(0px,0px)",
											lg: "scale(0.8) translate(30px,0px)",
										}}>
										<a
											href='https://www.producthunt.com/posts/horizon-ui?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-horizon&#0045;ui'
											target='_blank'>
											<img
												src='https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=342582&theme=light&period=daily'
												alt='Horizon&#0032;UI - Trendiest&#0032;open&#0045;source&#0032;React&#0032;admin&#0032;template | Product Hunt'
												style={{
													width: "250px",
													height: "54px",
													pointerEvents: "none",
												}}
												width='250'
												height='54'
											/>
										</a>
									</Box>
								</Flex>
							</Card>
						</Box>
					</Flex>
				</Flex>
			</InnerContent>
		</Flex>
	);
}
