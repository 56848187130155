import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminLayout from "layouts";
// Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
import Success from "layouts/success";
import theme from "theme/theme";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path={`/success-message`}>
            <Success />
          </Route>
          <Route path={`/success-message-ai`}>
            <Success />
          </Route>
          <Route path={`/`}>
            <AdminLayout />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
