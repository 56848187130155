import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";

// Admin Imports
import Free from "views/free";
import Success from "views/free/success";
import SuccessAI from "views/free/success-ai";

const routes = [
  // --- Dashboards ---
  {
    name: "Horizon UI",
    layout: "/",
    path: "/",
    component: Free,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  },
  // --- Dashboards ---
  {
    name: "Horizon UI",
    layout: "/",
    path: "/success-message",
    component: Success,
  },
  // --- Dashboards ---
  {
    name: "Horizon UI",
    layout: "/",
    path: "/success-message-ai",
    component: SuccessAI,
  },
];

export default routes;
