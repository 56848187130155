/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
	Button,
	Flex,
	Link,
	Icon,
	Image,
	useColorModeValue,
	Text,
} from "@chakra-ui/react";
// Assets
import image1 from "assets/img/free/configurator/configurator-image-1.png";
import image2 from "assets/img/free/configurator/configurator-image-2.png";
import { ConfiguratorBackground } from "components/icons/Icons";
import { MdChevronRight } from "react-icons/md";
// Custom components
import InnerContent from "layouts/innerContent";
import React from "react";

export default function Hero() {
	const textColor = useColorModeValue("#120F43", "white");
	const textColorSecondary = useColorModeValue("gray.600", "white");
	// Chakra Color Mode
	return (
		<Flex
			w='100%'
			direction='column'
			// pt={{
			//   base: "30px",
			//   md: "40px",
			//   xl: "60px",
			// }}
			pb={{ base: "100px", md: "140px", lg: "160px" }}
			position='relative'>
			<InnerContent
				w='1170px'
				maxW='100%'
				px={{ base: "0px", xl: "0px" }}
				zIndex='2'>
				<Link zIndex='2' href='https://horizon-ui.com/pro'>
					<Flex
						bg='linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)'
						borderRadius='45px'
						px='16px'
						py='6px'
						mb='-16px'
						zIndex={"3"}>
						<Text
							fontWeight={"700"}
							letterSpacing='2px'
							fontSize={"sm"}
							color='white'>
							EXCLUSIVELY IN PRO
						</Text>
					</Flex>
				</Link>

				<Flex
					bg='gray.50'
					p={{ base: "30px", md: "60px" }}
					pt={{ base: "100px", md: "100px", lg: "60px" }}
					borderRadius={"16px"}
					maxW='100%'
					direction='column'
					width='stretch'
					justify='center'
					align='center'
					overflow={"hidden"}
					position='relative'>
					<Icon
						as={ConfiguratorBackground}
						position='absolute'
						top={{ md: "350px", lg: "50px" }}
						right={{ md: "-270px", lg: "-240px" }}
						w='1310px'
						h='910px'
						zIndex={"1"}
					/>
					<Flex direction='column' zIndex='2'>
						<Flex
							align={{ base: "center", lg: "start" }}
							justify='center'
							direction={{ base: "column", lg: "row" }}
							width='100%'>
							<Flex
								alignItems={{ base: "center", lg: "unset" }}
								direction='column'>
								<Text
									as='h3'
									fontWeight='700'
									letterSpacing='2px'
									bg='brand.500'
									bgClip='text'
									fontSize={{ base: "xs", md: "md" }}
									textAlign={{ base: "center", lg: "left" }}
									w='100%'
									mb='10px'>
									THEME STYLES BASED ON YOUR NEEDS
								</Text>
								<Text
									as='h2'
									fontWeight='800'
									color={textColor}
									fontSize={{ base: "28px", md: "40px", xl: "48px" }}
									lineHeight={{ base: "34px", md: "50px", lg: "52px" }}
									mb='20px'
									w={{ md: "70%", lg: "100%" }}
									px={{ base: "20px", md: "unset" }}
									textAlign={{ base: "center", lg: "left" }}
									maxW={{ base: "100%", md: "unset" }}>
									Choose your theme style you like the most
								</Text>
								<Text
									color={textColorSecondary}
									textAlign={{ base: "center", lg: "left" }}
									fontSize={{ base: "md", md: "md", xl: "lg" }}
									w={{ base: "100%", md: "70%", lg: "100%" }}
									fontWeight='500'
									letterSpacing='0px'
									mb='30px'>
									Make your project the way you like it by choosing your
									favorite theme mode, sidebar state, and so on.
								</Text>
								<Flex
									align='center'
									direction={{ base: "column", md: "row" }}
									mb={{ md: "0px", lg: "30px" }}
									justifyContent={{ base: "center", lg: "unset" }}>
									<Link href='https://horizon-ui.com/pro'>
										<Button
											py='20px'
											px='16px'
											fontSize='sm'
											variant='tertiary'
											borderRadius='45px'
											me={{ base: "0px", md: "14px" }}
											mb={{ base: "20px", md: "0px" }}
											w={{ base: "335px", md: "210px" }}
											h='54px'>
											Get started with PRO
											<Icon
												as={MdChevronRight}
												ms='5px'
												mt='2px'
												h='16px'
												w='16px'
											/>
										</Button>
									</Link>
									<Link
										isExternal='true'
										href='https://horizon-ui.com/chakra-pro'>
										<Button
											py='20px'
											px='16px'
											fontSize='sm'
											variant='white'
											borderRadius='45px'
											w={{ base: "335px", md: "210px" }}
											h='54px'>
											Try it on live preview
											<Icon
												as={MdChevronRight}
												ms='5px'
												mt='2px'
												h='16px'
												w='16px'
											/>
										</Button>
									</Link>
								</Flex>
							</Flex>
							<Image
								alt='react theme configurator'
								borderRadius='18px'
								boxShadow={"0px 26.8349px 155.642px -46.156px #CBD5E0"}
								ms={{ base: "0px", lg: "50px" }}
								src={image1}
								w={{ base: "90%", md: "75%", lg: "400px", xl: "454px" }}
								mt={{ base: "40px", md: "40px", lg: "0px" }}
							/>
						</Flex>
						<Flex
							align={{ base: "center", lg: "end" }}
							justify='center'
							direction={{ base: "column", lg: "row-reverse" }}
							width='100%'
							mt={{ base: "100px", lg: "-100px", xl: "-220px" }}>
							<Flex
								direction='column'
								alignItems={{ base: "center", lg: "unset" }}>
								<Text
									as='h3'
									fontWeight='700'
									letterSpacing='2px'
									bg='brand.500'
									bgClip='text'
									fontSize={{ base: "xs", md: "md" }}
									textAlign={{ base: "center", lg: "left" }}
									w='100%'
									mb='10px'>
									GLOBAL COLOR PRESETS AND SETTINGS
								</Text>
								<Text
									as='h2'
									fontWeight='800'
									color={textColor}
									fontSize={{ base: "28px", md: "40px", xl: "48px" }}
									lineHeight={{ base: "34px", md: "50px", lg: "52px" }}
									mb='20px'
									w={{ md: "70%", lg: "100%" }}
									px={{ base: "30px", md: "unset" }}
									textAlign={{ base: "center", lg: "left" }}
									maxW={{ base: "100%", md: "unset" }}>
									Easy customization like never before
								</Text>
								<Text
									color={textColorSecondary}
									textAlign={{ base: "center", lg: "left" }}
									fontSize={{ base: "md", md: "md", xl: "lg" }}
									w={{ md: "70%", lg: "100%" }}
									fontWeight='500'
									mb='30px'
									letterSpacing='0px'>
									Customize and define your dashboard colors and contrast
									presets like never before with Horizon Configurator.
								</Text>
								<Flex
									align='center'
									direction={{ base: "column", md: "row" }}
									mb={{ md: "0px", lg: "30px" }}
									justifyContent={{ base: "center", lg: "unset" }}>
									<Link
										isExternal='true'
										href='https://horizon-ui.com/chakra-pro'>
										<Button
											py='20px'
											px='16px'
											fontSize='sm'
											variant='white'
											borderRadius='45px'
											w={{ base: "335px", md: "236px" }}
											h='54px'>
											Try the configurator live
											<Icon
												as={MdChevronRight}
												ms='5px'
												mt='2px'
												h='16px'
												w='16px'
											/>
										</Button>
									</Link>
								</Flex>
							</Flex>
							<Image
								alt='react theme examples'
								borderRadius='18px'
								boxShadow={"0px 26.8349px 155.642px -46.156px #CBD5E0"}
								me={{ base: "0px", lg: "55px", xl: "75px" }}
								src={image2}
								w={{ base: "90%", md: "75%", lg: "400px", xl: "454px" }}
								mt={{ base: "40px", md: "40px", lg: "0px" }}
							/>
						</Flex>
					</Flex>
				</Flex>
			</InnerContent>
		</Flex>
	);
}
