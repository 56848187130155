/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
	Flex,
	// Link,
	Text,
	useColorModeValue,
	SimpleGrid,
} from "@chakra-ui/react";
// Assets
import horizonuipro from "assets/img/free/ecosystem/horizon-ui-pro.png";
import horizonuiprologo from "assets/img/free/ecosystem/horizon-ui-pro-logo.png";
import horizonuiboilerplate from "assets/img/free/ecosystem/horizon-ui-boilerplate.png";
import horizonuiboilerplatelogo from "assets/img/free/ecosystem/horizon-ui-boilerplate-logo.png";
import horizonaiboilerplate from "assets/img/free/ecosystem/horizon-ai-boilerplate.png";
import horizonaiboilerplatefree from "assets/img/free/ecosystem/horizon-ai-boilerplate-free.png";
import horizonaiboilerplatelogo from "assets/img/free/ecosystem/horizon-ai-boilerplate-logo.png";
import horizonaitemplate from "assets/img/free/ecosystem/horizon-ai-template.png";
import horizonaitemplatelogo from "assets/img/free/ecosystem/horizon-ai-template-logo.png";
import horizonuicorporate from "assets/img/free/ecosystem/horizon-ui-corporate.png";
import horizonuicorporatelogo from "assets/img/free/ecosystem/horizon-ui-corporate-logo.png";
import horizonuikit from "assets/img/free/ecosystem/horizon-ui-kit.png";
import horizonuikitlogo from "assets/img/free/ecosystem/horizon-ui-kit-logo.png";
import horizonappgenerator from "assets/img/free/ecosystem/horizon-app-generator.png";
import horizonappgeneratorlogo from "assets/img/free/ecosystem/horizon-app-generator-logo.png";
// Custom components
import InnerContent from "layouts/innerContent";
import ToolCard from "./components/ToolCard";

export default function Tools() {
	// Chakra Color Mode
	const textColor = useColorModeValue("#120F43", "white");
	// const brandColor = useColorModeValue('brand.500', 'white');
	return (
		<Flex
			w='100%'
			maxW='100%'
			direction={{ base: "column" }}
			pb={{ base: "70px", md: "100px", lg: "120px" }}
			overflow='hidden'
			bgSize='cover'
			position='relative'
			id='version'>
			<Flex
				bg='linear-gradient(180deg, #FFFFFF 70.04%, #EEF3F9 100%)'
				borderRadius={"100%"}
				w={{ base: "310px", md: "500px", lg: "981px", xl: "981px" }}
				h={{ base: "310px", md: "500px", lg: "981px", xl: "981px" }}
				transform='translate(-50%, 0px) matrix(1, 0, 0, -1, 0, 0)'
				position={"absolute"}
				zIndex='1'
				left='50%'
			/>
			<InnerContent
				mt={{ base: "70px", md: "120px" }}
				zIndex='10'
				w='100%'
				justifyContent='flex-start'>
				<Flex
					maxW='100%'
					direction='column'
					justify='center'
					alignItems='center'
					width='stretch'
					px={{ base: "20px", md: "20px", xl: "0px" }}>
					<Flex
						direction='column'
						mx='auto'
						justify='center'
						alignItems='center'
						mb='40px'
						maxW={{ base: "100%", lg: "100%", xl: "70%" }}
						textAlign='center'>
						<Text
							as='h3'
							fontWeight='700'
							letterSpacing='2px'
							bg='brand.500'
							bgClip='text'
							fontSize={{ base: "xs", md: "md" }}
							w='100%'
							mb='10px'>
							HORIZON UI ECOSYSTEM
						</Text>
						<Text
							as='h2'
							color={textColor}
							fontWeight='800'
							fontSize={{ base: "28px", md: "40px", lg: "46px" }}
							lineHeight={{
								base: "34px",
								md: "50px",
								lg: "56px",
							}}
							w={{
								base: "100%",
								md: "70%",
								lg: "60%",
								xl: "70%",
								"2xl": "70%",
								"3xl": "70%",
							}}
							mb={{ base: "14px", lg: "30px" }}>
							Explore the entire Ecosystem of Horizon UI{" "}
						</Text>
						<Text
							color='gray.600'
							fontSize={{ base: "md", md: "md", xl: "lg" }}
							lineHeight='30px'
							fontWeight='500'
							letterSpacing='0px'
							w={{
								base: "100%",
								md: "70%",
								lg: "50%",
								xl: "70%",
								"2xl": "70%",
								"3xl": "70%",
							}}
							mb={{ base: "0px", xl: "40px" }}>
							Dive into the universe of Front-end & UI tools and discover all
							tools based on Horizon UI that are ready to help you!
						</Text>
					</Flex>
				</Flex>
				<SimpleGrid
					w='100%'
					columns={{ base: "1", md: "2", lg: "3" }}
					gap='20px'
					px={{ base: "20px", xl: "0px" }}>
					<ToolCard
						altImage='Horizon UI PRO'
						image={horizonuipro}
						logo={horizonuiprologo}
						title='Horizon UI PRO'
						description='PRO version of Admin template'
						link='https://horizon-ui.com/pro'
					/>
					<ToolCard
						altImage='Horizon UI Boilerplate'
						image={horizonuiboilerplate}
						logo={horizonuiboilerplatelogo}
						title='Horizon UI Boilerplate'
						description='Full-stack NextJS boilerplate'
						link='https://horizon-ui.com/boilerplate'
					/>
					<ToolCard
						altImage='Horizon AI Template'
						image={horizonaitemplate}
						logo={horizonaitemplatelogo}
						title='Horizon AI Template'
						description='ChatGPT React/NextJS template'
						link='https://horizon-ui.com/ai-template'
					/>
					<ToolCard
						altImage='Horizon UI Corporate'
						image={horizonuicorporate}
						logo={horizonuicorporatelogo}
						title='Horizon UI Corporate'
						description='Corporate version of Horizon UI'
						link='https://horizon-ui.com/pro-corporate'
					/>
					<ToolCard
						altImage='Horizon AI Shadcn UI PRO'
						image={horizonaiboilerplate}
						logo={horizonaiboilerplatelogo}
						title='Horizon AI Shadcn UI PRO'
						description='Shadcn UI Boilerplate PRO'
						link='https://horizon-ui.com/boilerplate-shadcn'
					/>
					<ToolCard
						altImage='Horizon AI Shadcn UI'
						image={horizonaiboilerplatefree}
						logo={horizonaiboilerplatelogo}
						title='Horizon AI Shadcn UI'
						description='Shadcn UI NextJS boilerplate'
						link='https://horizon-ui.com/shadcn-ui'
					/>
					<ToolCard
						altImage='Horizon UI Landing Kit'
						image={horizonuikit}
						logo={horizonuikitlogo}
						title='Horizon UI Landing Kit'
						description='Tailwind CSS Landing sections'
						link='https://horizon-ui.com/landing-kit'
					/>
					<ToolCard
						altImage='Horizon UI App Generator'
						image={horizonappgenerator}
						logo={horizonappgeneratorlogo}
						title='Horizon App Generator'
						description='ReactJS Web App Generator'
						link='https://horizon-ui.com/app-generator'
					/>
				</SimpleGrid>
			</InnerContent>
		</Flex>
	);
}
