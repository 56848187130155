/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
	Badge,
	Button,
	Flex,
	Link,
	Icon,
	Image,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
// Assets
// import backgroundHorizonFree from "assets/img/free/background-horizon-free.png";
import dashboardFreeHero from "assets/img/free/hero/main-hero-image.png";
import bgImg from "assets/img/free/hero/bg-hero-free.png";
import { HeroBg } from "components/icons/Icons";
import { MdChevronRight } from "react-icons/md";
// Custom components
import InnerContent from "layouts/innerContent";
import React from "react";

export default function Hero() {
	// Chakra Color Mode
	const textColor = useColorModeValue("#120F43", "white");
	const borderColor = useColorModeValue("gray.300", "white");
	return (
		<Flex
			w='100%'
			direction='column'
			pt={{
				base: "130px",
				md: "140px",
				xl: "190px",
				"2xl": "190px",
				"3xl": "200px",
			}}
			pb={{ base: "0px", md: "80px", lg: "80px", xl: "170px" }}
			position='relative'>
			<InnerContent
				w='1170px'
				maxW='100%'
				px={{ base: "20px", md: "40px", xl: "0px" }}
				zIndex='2'>
				<Flex
					w='100%'
					maxW='100%'
					direction='column'
					width='stretch'
					justify='center'
					align='center'>
					<Link href='https://horizon-ui.com/boilerplate-shadcn'>
						<Badge
							display='flex'
							alignItems='center'
							colorScheme='brand'
							borderRadius='25px'
							ps='14px'
							pe='10px'
							py='4px'
							mb='20px'
							maxW={{ base: "100%", md: "100%" }}>
							<Flex direction={{ base: "row", md: "row" }}>
								<Text
									color='brand.500'
									fontWeight='semibold'
									textTransform={"none"}
									letterSpacing='0px'
									fontSize={{ base: "xs", md: "sm" }}>
									Introducing Horizon AI Shadcn UI -
								</Text>
								<Text
									as='span'
									fontSize={{ base: "xs", md: "sm" }}
									color='brand.500'
									letterSpacing='0px'
									textTransform={"none"}
									fontWeight='500'
									ms='2px'>
									Learn more
								</Text>
							</Flex>
							<Icon ms='4px' as={MdChevronRight} />
						</Badge>
					</Link>
					<Flex
						direction='column'
						align='center'
						maxW='100%'
						textAlign='center'>
						<Text
							as='h1'
							color={textColor}
							fontSize={{ base: "26px", md: "48px", xl: "54px" }}
							lineHeight={{ base: "36px", md: "58px", xl: "64px" }}
							mb={{ base: "10px", md: "30px" }}
							fontWeight='700'>
							Trendiest open source
							<br />
							<Text
								as='h1'
								fontWeight='800'
								fontSize={{ base: "28px", md: "54px", xl: "58px" }}>
								React Admin Template
							</Text>
						</Text>
						<Text
							color='gray.600'
							fontSize={{ base: "sm", md: "md", xl: "lg" }}
							lineHeight={{ base: "24px", md: "30px", xl: "30px" }}
							fontWeight='500'
							letterSpacing='0px'
							w={{ base: "100%", lg: "60%", xl: "60%" }}
							mb='40px'>
							Start building your dashboard with Horizon UI, the popular Free
							React Admin Templates provider for Chakra UI, NextJS, and Tailwind
							CSS!
						</Text>
						<Flex
							align='center'
							direction={{ base: "column", md: "row" }}
							mb='30px'>
							<Link href='#version'>
								<Button
									py='20px'
									px='16px'
									fontSize='sm'
									variant='primary'
									borderRadius='45px'
									me={{ base: "0px", md: "14px" }}
									mb={{ base: "20px", md: "0px" }}
									w={{ base: "300px", md: "210px" }}
									h='54px'>
									Download for Free
									<Icon
										as={MdChevronRight}
										ms='5px'
										mt='2px'
										h='16px'
										w='16px'
									/>
								</Button>
							</Link>
							<Link
								isExternal='true'
								href='https://horizon-ui.com/horizon-tailwind-react'>
								<Button
									variant='transparent'
									border='1px solid'
									borderColor={borderColor}
									color={textColor}
									fontSize='sm'
									borderRadius='45px'
									my='auto'
									w={{ base: "300px", md: "180px" }}
									h='54px'>
									See live preview
								</Button>
							</Link>
						</Flex>
					</Flex>
				</Flex>
			</InnerContent>
			<Flex
				position='relative'
				mt='-20px'
				mb='20px'
				bgImage={bgImg}
				bgPosition='center'
				bgSize='cover'>
				{/* <Icon
					as={HeroBg}
					position='absolute'
					mx='auto'
					w={{ base: "700px", md: "750px", lg: "1000px", xl: "1618px" }}
					h={{ base: "700px", md: "750px", lg: "1000px", xl: "900px" }}
					left='50%'
					transform='translate(-50%,0px)'
					top={{ base: "-20px", md: "-40px", lg: "-100px", xl: "120px" }}
				/> */}
				<Image
					alt='free react admin dashboard template preview'
					boxShadow={"0px 26.8349px 155.642px -46.156px #CBD5E0"}
					top={{ base: "50px", lg: "60px", xl: "100px" }}
					position='relative'
					mx='auto'
					borderRadius='16px'
					src={dashboardFreeHero}
					w={{ base: "350px", md: "640px", lg: "740px", xl: "1170px" }}
					bottom='-20px'
					mt={{ base: "20px", lg: "unset" }}
				/>
			</Flex>
		</Flex>
	);
}
