/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import { Box, Button, Icon, Flex, Image, Link, Text } from "@chakra-ui/react";
// Assets
import dashboard from "assets/img/free/upgrade/upgrade-pro-main-dashboard.png";
import balance from "assets/img/free/upgrade/balance-card-pro.png";
import card from "assets/img/free/upgrade/light-card-pro.png";
import chart from "assets/img/free/upgrade/main-chart-pro.png";
import table from "assets/img/free/upgrade/table-card-pro.png";
import temperature from "assets/img/free/upgrade/temperature-card-pro.png";
import wallet from "assets/img/free/upgrade/wallet-card-pro.png";
import { MdChevronRight } from "react-icons/md";

// Custom components
import InnerContent from "layouts/innerContent";

export default function Upgrade() {
	// Chakra Color Mode
	return (
		<Flex w='100%' overflow='hidden' maxW='100%' direction={{ base: "column" }}>
			<InnerContent
				w={{ "2xl": "1560px" }}
				maxW={{ base: "100%" }}
				mx={{ base: "20px", "2xl": "auto" }}
				overflow='hidden'
				borderRadius='16px'
				bg='radial-gradient(58.11% 44.54% at 51.59% -9.61%, #B4B0FE 0%, #363285 22.92%, #110D5B 42.71%, #050327 88.54%)'>
				<Flex
					maxW='100%'
					direction='column'
					zIndex='5'
					width='stretch'
					mb={{ base: "0px", md: "30px" }}
					pt={{
						base: "30px",
						md: "40px",
						xl: "70px",
					}}>
					<Flex
						direction='column'
						mx='auto'
						mb='40px'
						px={{ base: "0px", md: "40px", xl: "0px" }}
						maxW={{ base: "100%", lg: "70%" }}
						textAlign='center'>
						<Text
							as='h3'
							fontWeight='700'
							letterSpacing='2px'
							bg='white'
							bgClip='text'
							fontSize={{ base: "xs", md: "md" }}
							w='100%'
							mb='10px'>
							IMPROVE YOUR DEVELOPMENT PROCESS
						</Text>
						<Text
							as='h2'
							color={"white"}
							fontWeight='800'
							fontSize={{ base: "34px", md: "40px", xl: "48px" }}
							lineHeight={{ base: "42px", md: "50px", lg: "52px" }}
							px={{ base: "40px", md: "0px" }}
							mb={{ base: "14px", lg: "20px" }}>
							Start doing more with PRO
						</Text>
						<Text
							mb='30px'
							color={"white"}
							alignSelf='center'
							fontSize={{ base: "md", md: "md", xl: "lg" }}
							letterSpacing='0px'
							fontWeight={"500"}
							px={{ base: "20px", md: "0px" }}
							w={{ base: "100%", xl: "70%", "2xl": "62%" }}>
							Save hundreds of hours trying to create and develop a dashboard
							from scratch. The fastest, most responsive & trendiest admin
							template is here. Seriously.
						</Text>{" "}
						<Flex
							px={{ base: "20px", md: "0px" }}
							direction={{ base: "column", md: "row" }}
							align='center'
							justify='center'
							mb='30px'>
							<Flex
								position={"relative"}
								mb={{ base: "14px", md: "0px" }}
								w={{ base: "100%", md: "unset" }}
								me={{ base: "0px", md: "14px" }}
								direction='column'
								pt='12px'
								pb='21px'
								px='24px'
								borderRadius={"12px"}
								bg='linear-gradient(180deg, rgba(255, 255, 255, 0.204) 0%, rgba(255, 255, 255, 0.051) 100%)'
								overflow={"hidden"}>
								<Flex
									filter='blur(21px)'
									w='100%'
									h='100%'
									position={"absolute"}
								/>
								<Text
									color={"white"}
									fontWeight='800'
									textAlign={"center"}
									mb='10px'
									fontSize='38px'
									lineHeight='100%'>
									400+
								</Text>
								<Text
									color={"gray.300"}
									textAlign={"center"}
									fontWeight='700'
									fontSize='sm'
									letterSpacing='2px'>
									COMPONENTS
								</Text>
							</Flex>
							<Flex
								mb={{ base: "14px", md: "0px" }}
								position={"relative"}
								w={{ base: "100%", md: "unset" }}
								me={{ base: "0px", md: "14px" }}
								direction='column'
								pt='12px'
								pb='21px'
								px='24px'
								borderRadius={"12px"}
								bg='linear-gradient(180deg, rgba(255, 255, 255, 0.204) 0%, rgba(255, 255, 255, 0.051) 100%)'
								overflow={"hidden"}>
								<Flex
									filter='blur(21px)'
									w='100%'
									h='100%'
									position={"absolute"}
								/>
								<Text
									color={"white"}
									fontWeight='800'
									textAlign={"center"}
									mb='10px'
									fontSize='38px'
									lineHeight='100%'>
									44+
								</Text>
								<Text
									color={"gray.300"}
									textAlign={"center"}
									fontWeight='700'
									fontSize='sm'
									letterSpacing='2px'>
									PAGE EXAMPLES
								</Text>
							</Flex>
							<Flex
								w={{ base: "100%", md: "unset" }}
								position={"relative"}
								direction='column'
								pt='12px'
								pb='21px'
								px='24px'
								borderRadius={"12px"}
								bg='linear-gradient(180deg, rgba(255, 255, 255, 0.204) 0%, rgba(255, 255, 255, 0.051) 100%)'
								overflow={"hidden"}>
								<Flex
									filter='blur(21px)'
									w='100%'
									h='100%'
									position={"absolute"}
								/>
								<Text
									color={"white"}
									fontWeight='800'
									textAlign={"center"}
									mb='10px'
									fontSize='38px'
									lineHeight='100%'>
									10+
								</Text>
								<Text
									color={"gray.300"}
									textAlign={"center"}
									fontWeight='700'
									fontSize='sm'
									letterSpacing='2px'>
									CATEGORIES
								</Text>
							</Flex>
						</Flex>
						<Flex
							align='center'
							direction={{ base: "column", md: "row" }}
							justifyContent={{ base: "center", lg: "center" }}>
							<Link href='https://horizon-ui.com/pro'>
								<Button
									variant='primary'
									py='20px'
									px='16px'
									fontSize='sm'
									borderRadius='45px'
									me={{ base: "0px", md: "14px" }}
									mb={{ base: "20px", md: "0px" }}
									w={{ base: "300px", md: "210px" }}
									h='54px'>
									Get started with PRO
									<Icon as={MdChevronRight} color='white' h='16px' w='16px' />
								</Button>
							</Link>
							<Link isExternal='true' href='https://horizon-ui.com/chakra-pro'>
								<Button
									bg='whiteAlpha.200'
									_hover={{ bg: "whiteAlpha.300" }}
									_active={{ bg: "whiteAlpha.200" }}
									color='white'
									fontSize='sm'
									borderRadius='45px'
									my='auto'
									w={{ base: "300px", md: "180px" }}
									h='54px'>
									See live preview
								</Button>
							</Link>
						</Flex>
					</Flex>
				</Flex>
				<Flex>
					<Box mt='auto' position='relative'>
						<Flex position={"relative"}>
							<Image
								alt='horizon ui pro - premium react template'
								zIndex={"2"}
								w={{ base: "310px", md: "640px", lg: "800px", xl: "956px" }}
								position='relative'
								src={dashboard}
							/>
							<Image
								alt='horizon ui pro - premium react template temperature card'
								zIndex={"2"}
								display={{ base: "none", md: "block" }}
								maxH='max-content'
								position='absolute'
								borderRadius='16px'
								overflow='hidden'
								transform={"rotate(15deg)"}
								w={{ base: "150px", md: "120px", lg: "150px", xl: "200px" }}
								boxShadow={
									"19.3196px 32.6018px 84.5231px 2.82786px rgba(60, 76, 92, 0.28)"
								}
								top={{ md: "64px", lg: "89px" }}
								left={{
									base: "-100px",
									md: "-74px",
									lg: "-100px",
									xl: "-196px",
								}}
								src={temperature}
							/>
							<Image
								alt='horizon ui pro - premium react template balance card'
								zIndex={"2"}
								display={{ base: "none", md: "block" }}
								maxH='max-content'
								position='absolute'
								borderRadius='16px'
								overflow='hidden'
								w={{ base: "70px", md: "130px", lg: "156px", xl: "260px" }}
								transform={"rotate(-9.5deg)"}
								boxShadow={
									"18.8829px 31.865px 82.6129px 2.76395px rgba(60, 76, 92, 0.28)"
								}
								left={{
									base: "-100px",
									md: "-60px",
									lg: "-98px",
									xl: "-200px",
								}}
								bottom={{
									base: "-40px",
									md: "-40px",
									lg: "-40px",
									xl: "-80px",
								}}
								src={balance}
							/>
							<Image
								alt='horizon ui pro - premium react template chart card'
								zIndex={"2"}
								display={{ base: "none", md: "block" }}
								maxH='max-content'
								position='absolute'
								borderRadius='16px'
								overflow='hidden'
								w={{ base: "90px", md: "140px", lg: "150px", xl: "192px" }}
								transform={"rotate(-15deg)"}
								boxShadow={"16px 27px 70px 2.34196px rgba(60, 76, 92, 0.28)"}
								top='30px'
								right={{
									base: "-110px",
									md: "-90px",
									lg: "-110px",
									xl: "-167px",
								}}
								src={card}
							/>
							<Image
								alt='horizon ui pro - premium react template wallet card'
								zIndex={"2"}
								display={{ base: "none", md: "block" }}
								maxH='max-content'
								position='absolute'
								borderRadius='16px'
								overflow='hidden'
								transform={"rotate(15deg)"}
								boxShadow={"16px 27px 70px 2.34196px rgba(60, 76, 92, 0.28)"}
								right={{
									base: "-110px",
									md: "-60px",
									lg: "-130px",
									xl: "-200px",
								}}
								w={{ base: "100px", md: "130px", lg: "200px", xl: "264px" }}
								top={{ md: "274px", lg: "333px" }}
								src={wallet}
							/>
							<Image
								alt='horizon ui pro - premium react template chart crypto card'
								zIndex={"2"}
								maxH='max-content'
								position='absolute'
								borderRadius={{ base: "4px", md: "16px" }}
								overflow='hidden'
								w={{ base: "128px", md: "270px", lg: "328px", xl: "402px" }}
								left={{ base: "45px", md: "90px", lg: "120px", xl: "133px" }}
								boxShadow={
									"15.5175px 26.1858px 67.8892px 2.27134px rgba(60, 76, 92, 0.28)"
								}
								top={{ base: "35px", md: "70px", lg: "86px", xl: "107px" }}
								src={chart}
							/>
							<Image
								alt='horizon ui pro - premium react template table card'
								zIndex={"2"}
								display={{ base: "none", md: "block" }}
								maxH='max-content'
								position='absolute'
								borderRadius='16px'
								overflow='hidden'
								boxShadow='17.9411px 30.2756px 78.4923px 2.62609px rgba(60, 76, 92, 0.28)'
								src={table}
								w={{ base: "150px", md: "270px", lg: "350px", xl: "402px" }}
								left={{ base: "300px", md: "240px", lg: "300px", xl: "340px" }}
								bottom={{ base: "-60px", md: "-60px", lg: "-80px" }}
							/>
							{/* Purple Shadow */}
							<Flex
								left='50%'
								transform={"translate(-50%, 0px)"}
								position={"absolute"}
								w={{ base: "100%", md: "150%", lg: "100%", xl: "150%" }}
								h='590px'
								bottom={{
									base: "-350px",
									md: "-180px",
									lg: "-120px",
									xl: "-80px",
								}}
								border-radius='999px'
								bg='#5D4FFF'
								filter='blur(80px)'
								backdropBlur='80px'
								zIndex={"1"}
							/>
						</Flex>
					</Box>
				</Flex>
			</InnerContent>
		</Flex>
	);
}
