/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
	Button,
	Flex,
	Link,
	Icon,
	Image,
	SimpleGrid,
	Text,
} from "@chakra-ui/react";
// Assets
import onePlace from "assets/img/free/onePlace/turn-data-image.png";
import { MdChevronRight } from "react-icons/md";
// Custom components
import InnerContent from "layouts/innerContent";
import React from "react";

export default function Hero() {
	// Chakra Color Mode
	return (
		<Flex
			w='100%'
			direction='column'
			pt={{
				base: "30px",
				md: "40px",
				xl: "70px",
			}}
			position='relative'>
			<InnerContent
				w='1170px'
				maxW='100%'
				px={{ base: "20px", xl: "0px" }}
				zIndex='2'>
				<Flex
					bg='linear-gradient(15.46deg, #070523 26.3%, #0A0559 91.13%)'
					borderRadius={"16px"}
					maxW='100%'
					direction='column'
					width='stretch'
					justify='center'
					align='center'
					overflow={"hidden"}>
					<Flex
						direction={{ base: "column", lg: "row" }}
						justify='space-between'
						ps={{ base: "30px", md: "60px", lg: "56px", xl: "60px" }}
						pt={{ base: "30px", md: "60px", lg: "56px", xl: "60px" }}>
						<Flex
							direction='column'
							alignItems={{ base: "center", lg: "inherit" }}
							me={{ base: "0px", xl: "40px" }}
							pb={{ base: "30px", md: "60px", lg: "56px", xl: "40px" }}
							pe={{ base: "30px", md: "60px", lg: "0px" }}>
							<Text
								as='h3'
								mb='10px'
								letterSpacing={"1px"}
								fontWeight={"700"}
								color='#21CBFF'
								fontSize={{ base: "xs", md: "md" }}
								textAlign={{ base: "center", lg: "start" }}>
								SEE ALL YOUR DATA IN ONE PLACE
							</Text>
							<Text
								as='h2'
								mb='22px'
								fontSize={{ base: "28px", md: "40px", xl: "48px" }}
								fontWeight={"800"}
								color='#ffffff'
								textAlign={{ base: "center", lg: "start" }}
								lineHeight={{ base: "34px", md: "50px", lg: "52px" }}
								width={{ md: "80%", lg: "100%" }}>
								Turn your data into a beautiful dashboard
							</Text>
							<Text
								fontSize={{ base: "md", md: "md", xl: "md" }}
								letterSpacing='0px'
								fontWeight={"500"}
								w={{ base: "100%", md: "90%", lg: "96%" }}
								textAlign={{ base: "center", lg: "start" }}
								color='gray.300'
								mb='30px'>
								The beauty of Horizon' s react templates is that you can give
								life to all of your data into a dashboard with a very modern and
								beautiful UI.
							</Text>
							<Flex
								align='center'
								direction={{ base: "column", md: "row" }}
								mb='40px'
								justifyContent={{ base: "center", lg: "start" }}>
								<Link href='#version'>
									<Button
										py='20px'
										px='16px'
										fontSize='sm'
										variant='secondary'
										borderRadius='45px'
										me={{ base: "0px", md: "14px" }}
										mb={{ base: "20px", md: "0px" }}
										w={{ base: "300px", md: "210px" }}
										h='54px'>
										Download for Free
										<Icon
											as={MdChevronRight}
											ms='5px'
											mt='2px'
											h='16px'
											w='16px'
										/>
									</Button>
								</Link>
								<Link
									isExternal='true'
									href='https://horizon-ui.com/horizon-tailwind-react'>
									<Button
										bg='whiteAlpha.200'
										_hover={{ bg: "whiteAlpha.300" }}
										_active={{ bg: "whiteAlpha.200" }}
										color='white'
										fontSize='sm'
										borderRadius='45px'
										my='auto'
										w={{ base: "300px", md: "180px" }}
										h='54px'>
										See live preview
									</Button>
								</Link>
							</Flex>
							<SimpleGrid
								gap='14px'
								width='100%'
								columns={{ base: "1", md: "2" }}>
								<Flex
									direction={"column"}
									px='24px'
									py='18px'
									borderRadius='16px'
									bg='linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.048) 100%)'>
									<Text
										mb='6px'
										fontSize='38px'
										fontWeight={"800"}
										color='#ffffff'
										lineHeight={"104%"}>
										70+
									</Text>
									<Text
										fontSize='sm'
										letterSpacing='2px'
										fontWeight={"700"}
										color='gray.300'>
										COMPONENTS
									</Text>
								</Flex>
								<Flex
									direction={"column"}
									px='24px'
									py='18px'
									borderRadius='16px'
									bg='linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.048) 100%)'>
									<Text
										mb='6px'
										fontSize='38px'
										fontWeight={"800"}
										color='#ffffff'
										lineHeight={"104%"}>
										06
									</Text>
									<Text
										fontSize='sm'
										letterSpacing='2px'
										fontWeight={"700"}
										color='gray.300'>
										PAGE EXAMPLES
									</Text>
								</Flex>
								<Flex
									direction={"column"}
									px='24px'
									py='18px'
									borderRadius='16px'
									bg='linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.048) 100%)'>
									<Text
										mb='6px'
										fontSize='38px'
										fontWeight={"800"}
										color='#ffffff'
										lineHeight={"104%"}>
										02
									</Text>
									<Text
										fontSize='sm'
										letterSpacing='2px'
										fontWeight={"700"}
										color='gray.300'>
										THEME MODES
									</Text>
								</Flex>
								<Flex
									direction={"column"}
									px='24px'
									py='18px'
									borderRadius='16px'
									bg='linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.048) 100%)'>
									<Text
										mb='6px'
										fontSize='38px'
										fontWeight={"800"}
										color='#ffffff'
										lineHeight={"104%"}>
										09
									</Text>
									<Text
										fontSize='sm'
										letterSpacing='2px'
										fontWeight={"700"}
										color='gray.300'>
										TECHNOLOGIES
									</Text>
								</Flex>
							</SimpleGrid>
						</Flex>
						<Image
							alt='free react admin dashboard preview'
							w={{ base: "100%", lg: "60%", xl: "50%" }}
							h='100%'
							src={onePlace}
						/>
					</Flex>
				</Flex>
			</InnerContent>
		</Flex>
	);
}
