/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Custom components
import Hero from "sections/free/hero";
import Associates from "sections/free/associates";
import Upgrade from "sections/free/upgrade";
import Version from "sections/free/version";
import Ecosystem from "sections/free/ecosystem";
import TurnData from "sections/free/TurnData";
import Configurator from "sections/free/Configurator";
import OpenSource from "sections/free/openSource";
import Numbers from "sections/free/numbers";
export default function Default() {
	return (
		<Flex overflow={"hidden"} direction={{ base: "column" }}>
			<Hero />
			<Associates />
			<Version />
			<Ecosystem />
			<TurnData />
			<OpenSource />
			<Configurator />
			<Numbers />
			<Upgrade />
		</Flex>
	);
}
