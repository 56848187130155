/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
	Button,
	Flex,
	Link,
	Image,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import Check from "assets/img/free/success/image-check.png";
import { Gmail, Yahoo } from "components/icons/Icons";
import InnerContent from "layouts/innerContent";
// Custom components
export default function Default() {
	const textColor = useColorModeValue("#120F43", "white");
	const borderColor = useColorModeValue("gray.300", "white");
	return (
		<Flex
			w='100%'
			direction={{ base: "column" }}
			pt={{
				base: "100px",
				md: "140px",
				xl: "140px",
				"2xl": "160px",
				"3xl": "160px",
			}}
			overflow='hidden'
			bgSize='cover'
			position='relative'>
			<Flex
				bg='linear-gradient(180deg, #FFFFFF 70.04%, #EEF3F9 100%)'
				borderRadius={"100%"}
				w={{ base: "310px", md: "500px", lg: "981px", xl: "981px" }}
				h={{ base: "310px", md: "500px", lg: "981px", xl: "981px" }}
				transform='translate(-50%, 0px) matrix(1, 0, 0, -1, 0, 0)'
				position={"absolute"}
				zIndex='1'
				left='50%'
			/>
			<InnerContent
				zIndex='2'
				pt={{ base: "50px", md: "80px" }}
				px={{ base: "20px", md: "40px", xl: "0px" }}>
				<Flex
					direction={{ base: "column" }}
					width='100%'
					align='center'
					justify={"center"}>
					<Image mb='30px' src={Check} w='128px' />
					<Text
						textAlign={"center"}
						color='#120F43'
						fontWeight='700'
						fontSize={{ base: "36px", md: "34px", lg: "40px" }}
						lineHeight={{ base: "48px", md: "48px", lg: "54px" }}
						mb={{ base: "10px", md: "0px" }}>
						You did it! Thanks for downloading!
					</Text>
					<Text
						textAlign={"center"}
						color='#120F43'
						fontWeight='500'
						fontSize={{ base: "22px", md: "34px", lg: "40px" }}
						lineHeight={{ base: "36px", md: "48px", lg: "54px" }}
						maxW={{ base: "94%", lg: "74%" }}
						mb='34px'>
						Please check your email to download your free product.
					</Text>
					<Text color='#4A5568' fontSize='md' mb='34px' letterSpacing='0px'>
						<Text
							as='span'
							me='2px'
							color='#120F43'
							fontSize='md'
							mb='34px'
							fontWeight='700'
							letterSpacing='0px'>
							Note:
						</Text>
						The download email can arrive in the Spam Inbox.
					</Text>
					<Flex
						align='center'
						direction={{ base: "column", md: "row" }}
						mb={{ base: "80px", md: "90px" }}>
						<Link
							isExternal='true'
							href='https://mail.google.com/'
							me={{ base: "0px", md: "14px" }}
							mb={{ base: "20px", md: "0px" }}>
							<Button
								variant='silver'
								py='20px'
								px='30px'
								display={"flex"}
								alignItems='center'
								justifyContent='center'
								fontSize='sm'
								borderRadius='45px'
								w={{ base: "240px", xl: "240px" }}
								h='54px'>
								Open Gmail
								<Gmail ms='5px' h='15px' w='20px' />
							</Button>
						</Link>
						<Link href='https://mail.yahoo.com/' isExternal='true'>
							<Button
								py='20px'
								px='16px'
								fontSize='sm'
								variant='primary'
								borderRadius='45px'
								w={{ base: "240px", xl: "240px" }}
								h='54px'>
								Open
								<Yahoo h='16px' w='85px' />
							</Button>
						</Link>
					</Flex>
					<Flex align='center' justify={"center"} direction='column'>
						<Text color='#4A5568' fontSize='md' mb='14px'>
							Looking for something else?
						</Text>
						<Link href='/'>
							<Button
								variant='transparent'
								border='1px solid'
								borderColor={borderColor}
								color={textColor}
								fontSize='sm'
								borderRadius='45px'
								my='auto'
								px='40px'
								h='54px'>
								Go back to homepage
							</Button>
						</Link>
					</Flex>
				</Flex>
			</InnerContent>
		</Flex>
	);
}
