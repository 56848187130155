/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
	Flex,
	Link,
	Button,
	Icon,
	Image,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";

// Assets
import imageLight from "assets/img/free/components/components-image.png";
import { MdChevronRight } from "react-icons/md";

// Custom components
import InnerContent from "layouts/innerContent";

export default function GetStarted() {
	const image = useColorModeValue(imageLight, imageLight);
	// Chakra Color Mode
	const textColor = useColorModeValue("#120F43", "white");
	const textColorSecondary = useColorModeValue("gray.600", "white");
	return (
		<Flex
			w='100%'
			direction={{ base: "column" }}
			pt={{ base: "100px", md: "140px", lg: "140px" }}
			pb={{ base: "100px", md: "140px", lg: "140px" }}
			overflow='hidden'
			bgSize='cover'
			position='relative'>
			<InnerContent px={{ base: "20px", md: "40px", xl: "0px" }}>
				<Flex
					align={"center"}
					direction={{ base: "column-reverse", lg: "row" }}
					width='100%'
					alignItems={{ base: "center", lg: "unset" }}>
					<Image
						alt='react components library'
						me={{ base: "0px", lg: "70px", xl: "70px" }}
						src={image}
						w={{ base: "90%", md: "100%", lg: "470px", xl: "620px" }}
						ms={{ base: "0px", lg: "-40px" }}
						mt={{ base: "40px", md: "40px", lg: "0px" }}
					/>
					<Flex
						direction='column'
						my='auto'
						alignItems={{ base: "center", lg: "unset" }}>
						<Text
							as='h3'
							fontWeight='700'
							letterSpacing='2px'
							bg='brand.500'
							bgClip='text'
							fontSize={{ base: "xs", md: "md" }}
							textAlign={{ base: "center", lg: "left" }}
							w='100%'
							mb='10px'>
							BIG HIGH-QUALITY PACK OF ELEMENTS
						</Text>
						<Text
							as='h2'
							fontWeight='800'
							color={textColor}
							fontSize={{ base: "28px", md: "38px", xl: "46px" }}
							lineHeight={{ base: "34px", md: "48px", xl: "50px" }}
							mb='20px'
							w={{ base: "100%", md: "70%", lg: "100%" }}
							textAlign={{ base: "center", lg: "left" }}
							maxW={{ base: "100%", md: "unset" }}>
							Huge pack of top notch React components
						</Text>
						<Text
							color={textColorSecondary}
							textAlign={{ base: "center", lg: "left" }}
							fontSize={{ base: "md", md: "md", xl: "md" }}
							w={{ base: "100%", md: "80%", lg: "100%" }}
							fontWeight='500'
							letterSpacing='0px'
							mb='30px'>
							Horizon UI comes with over 70+ dark/light frontend individual
							elements, like buttons, inputs, navbars, nav tabs, cards, or
							alerts, all part of our react admin template free download
							collection.
						</Text>
						<Flex
							align='center'
							direction={{ base: "column", md: "row" }}
							mb={{ md: "0px", lg: "30px" }}
							justifyContent={{ base: "center", lg: "unset" }}>
							<Link href='https://horizon-ui.com/components'>
								<Button
									py='20px'
									px='16px'
									fontSize='sm'
									variant='primary'
									borderRadius='45px'
									me={{ base: "0px", md: "20px" }}
									mb={{ base: "20px", md: "0px" }}
									w={{ base: "335px", md: "210px" }}
									h='54px'>
									View all Components
									<Icon
										as={MdChevronRight}
										ms='5px'
										mt='2px'
										h='16px'
										w='16px'
									/>
								</Button>
							</Link>
						</Flex>
					</Flex>
				</Flex>
			</InnerContent>
		</Flex>
	);
}
