/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI 
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2024 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex, Icon, Box, Text } from "@chakra-ui/react";
// Assets
// Custom components
import InnerContent from "layouts/innerContent";
import React from "react";
import {
	CALCOM,
	CISCO,
	ENVATO,
	INFOSYS,
	MICROSOFT,
	SAMSUNG,
} from "components/icons/Icons";
import { IoIosStar } from "react-icons/io";

export default function Hero() {
	// Chakra Color Mode
	return (
		<Flex
			w='100%'
			direction='column'
			pt={{
				base: "150px",
				md: "70px",
				xl: "70px",
				"2xl": "70px",
				"3xl": "70px",
			}}
			pb={{ base: "0px", md: "0px", xl: "50px" }}
			position='relative'>
			<InnerContent
				w='1170px'
				maxW='100%'
				px={{ base: "20px", md: "40px", xl: "0px" }}
				zIndex='2'>
				<Text
					color='gray.400'
					textAlign={{ base: "center" }}
					fontWeight={"700"}
					fontSize={{ base: "xs", md: "md" }}
					letterSpacing='2px'
					mb='40px'>
					JOIN 30,000+ DEVELOPERS & BUSINESSES THAT USE HORIZON UI
				</Text>
				<Flex
					flexWrap={{ base: "wrap", xl: "nowrap" }}
					justify='center'
					alignItems='center'
					gap='60px'
					mb='40px'>
					<Icon
						w={{ base: "130px", md: "161px" }}
						h={{ base: "36px", md: "26px" }}
						as={SAMSUNG}
					/>
					<Icon
						w={{ base: "130px", md: "160px" }}
						h={{ base: "36px", md: "34px" }}
						as={MICROSOFT}
					/>
					<Icon
						w={{ base: "130px", md: "160px" }}
						h={{ base: "36px", md: "34px" }}
						as={CALCOM}
					/>
					<Icon
						w={{ base: "86px", md: "97px" }}
						h={{ base: "36px", md: "52px" }}
						as={CISCO}
					/>
					<Icon
						w={{ base: "130px", md: "160px" }}
						h={{ base: "36px", md: "31px" }}
						as={ENVATO}
					/>
					<Icon
						w={{ base: "100px", md: "117px" }}
						h={{ base: "36px", md: "48px" }}
						as={INFOSYS}
					/>
				</Flex>
				<Flex gap='25px' direction={{ base: "column", md: "row" }}>
					{/* <Link href="https://www.producthunt.com/products/horizon-ui-pro?utm_source=badge-top-post-badge&utm_medium=badge#horizon-ui">
            <Image src={producthunt} />
          </Link> */}
					<Box
						mt={{ base: "20px", md: "unset" }}
						// transform={{
						//   base: "scale(0.8) translate(-30px,0px)",
						//   md: "scale(0.8) translate(30px,0px)",
						// }}
					>
						<a
							href='https://www.producthunt.com/posts/horizon-ui?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-horizon&#0045;ui'
							target='blank'>
							<img
								src='https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=342582&theme=light&period=daily'
								alt='Horizon&#0032;UI - Trendiest&#0032;open&#0045;source&#0032;React&#0032;admin&#0032;template | Product Hunt'
								style={{
									width: "250px",
									height: "54px",
									pointerEvents: "none",
								}}
								width='250'
								height='54'
							/>
						</a>
					</Box>
					<Flex direction={"column"} justify={{ base: "center", md: "start" }}>
						<Flex justify={{ base: "center", md: "start" }} alignItems='center'>
							<Icon as={IoIosStar} w='22px' h='22px' color='orange.300' />
							<Icon as={IoIosStar} w='22px' h='22px' color='orange.300' />
							<Icon as={IoIosStar} w='22px' h='22px' color='orange.300' />
							<Icon as={IoIosStar} w='22px' h='22px' color='orange.300' />
							<Icon
								as={IoIosStar}
								w='22px'
								h='22px'
								color='orange.300'
								me='8px'
							/>
							<Text color='#120F43' fontWeight='bold' fontSize='lg'>
								4150+
							</Text>
						</Flex>
						<Text
							color='gray.600'
							textAlign={{ base: "center", md: "start" }}
							fontWeight='500'
							letterSpacing='0px'
							fontSize='md'>
							Total stars on Github repos
						</Text>
					</Flex>
				</Flex>
			</InnerContent>
		</Flex>
	);
}
